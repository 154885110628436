import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { setTourProps } from '@redux/actions/action-tour';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import tourConfig, { type TourConfig } from '@components/AppTour/tourConfig';

export interface ShowTourProps {
  routeMask: keyof TourConfig;
}

const ShowTour = (props: ShowTourProps) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(state => state.auth);
  const app = useAppSelector(state => state.app);
  const { isAdmin } = auth.account;

  // Remove account context from the route mask
  let { routeMask } = props;

  // Process routeMask as a string.
  const routeMaskTmp = routeMask.split('/');
  routeMaskTmp.splice(0, 2);
  routeMask = `/${routeMaskTmp.join('/')}` as keyof TourConfig;

  // Is there a tour for this route?
  const tourData = tourConfig()[routeMask];
  const { tour } = app;
  let el: React.ReactElement | null = null;

  // If there is a tour for this route and the user is an admin or the tour is
  // not admin only, show the tour button
  if (
    tourData &&
    ((tourData[0].adminOnly && isAdmin) || !tourData[0].adminOnly)
  ) {
    el = (
      <Button
        fluid
        size="tiny"
        onClick={() => {
          dispatch(
            setTourProps({
              ...tour.tourProps,
              [routeMask]: {
                isDisabled: false,
                showIntro: true,
              },
            }),
          );
        }}
      >
        <Icon name="info circle" />
        Learn more about this view
      </Button>
    );
  }

  return el;
};

export default ShowTour;
