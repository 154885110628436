import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import type { CreateEntityActionOptions } from './createEntityAction';
import getEntityObject from './getEntityObject';

const createEntityReducers = <State, Actions>(
  entities: CreateEntityActionOptions<Actions>,
  builder: ActionReducerMapBuilder<State>,
) => {
  Object.keys(entities).forEach(entity => {
    const { thunk, stateKey, pending, rejected, fulfilled } =
      entities[entity as keyof Actions];

    builder
      .addCase(thunk.pending, (state, action) => {
        const [base, key] = getEntityObject(state, stateKey);
        base[key].status = 'loading';
        pending?.(state, action);
      })
      .addCase(thunk.fulfilled, (state, action) => {
        const [base, key] = getEntityObject(state, stateKey);
        base[key] = {
          status: 'ready',
          ...action.payload.body,
        };
        fulfilled?.(state, action);
      })
      .addCase(thunk.rejected, (state, action) => {
        const [base, key] = getEntityObject(state, stateKey);
        base[key] = {
          ...base[key],
          status: 'error',
          error: action.payload || action.error,
        };
        rejected?.(state, action);
      });
  });
};

export default createEntityReducers;
