import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Image } from 'semantic-ui-react';

import metadata from '@/../package.json';

import type { StepsConfig } from '../tourConfig';

import s from './TourSteps.scss';

const stepStyles = {
  options: {
    width: '65rem',
  },
};

const IntroStep = () => {
  useStyles(s);
  return (
    <div className={s.root}>
      <h2 className={s.welcomeHeader}>
        <span>
          <span>Welcome to Anchore Enterprise Client</span>
          &nbsp;
          <span
            className={classNames(
              'animate__animated animate__rotateIn',
              s.version,
            )}
          >
            {metadata.version.split('.').slice(0, 2).join('.')}
          </span>
        </span>
        <Image src="/img/robot_box.svg" className={s.robotBoxImg} />
      </h2>
      <p>
        We&#39;ve added new features, addressed known issues, and applied a
        generous helping of latest-version goodness in the form of security
        updates and performance improvements.
      </p>
      <p>
        We hope you enjoy using the application and would love to hear your
        feedback. Please contact us via&nbsp;
        <a
          target="_blank"
          href="https://support.anchore.com"
          rel="noopener noreferrer"
        >
          support.anchore.com
        </a>
        &nbsp;for any issues or questions.
      </p>
      <p>
        And to those of you who are new to Anchore—<em>welcome</em>!
      </p>
      {/* <p>
        Go on, click <strong>Next</strong>... You&#39;ll be glad you did{' '}
      </p> */}
    </div>
  );
};

// const FinalStep = () => {
//   useStyles(s);
//   return (
//     <div className={s.root}>
//       <h2>
//         <em>&hellip;and More!</em>
//       </h2>
//       <p>
//         Refer to the details provided in the&nbsp;
//         <strong>What&#39;s New in This Release</strong> dropdown to see what
//         else we&#39;ve been up to.
//       </p>
//       <p>
//         We hope you enjoy using the application and would love to hear your
//         feedback. Please contact us via&nbsp;
//         <a
//           target="_blank"
//           href="https://support.anchore.com"
//           rel="noopener noreferrer"
//         >
//           support.anchore.com
//         </a>
//         &nbsp;for any issues or questions.
//       </p>
//       <p>
//         And to those of you who are new to Anchore—<em>welcome</em>!
//       </p>
//       <Image
//         centered
//         src="/img/robot_saucer2.svg"
//         className={s.robotSaucerImg}
//       />
//     </div>
//   );
// };

export default (): StepsConfig => ({
  id: 'main',
  steps: [
    {
      styles: stepStyles,
      content: <IntroStep />,
      placement: 'center',
      target: 'body',
    },
  ],
});
