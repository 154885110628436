import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Loader,
  Menu,
  Modal,
  Popup,
  Segment,
} from 'semantic-ui-react';

import { getUserPermsSync } from '@/rbac';
import { SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS } from '@redux/actionTypes';
import arePermsGoodForRoute from '@routes/arePermsGoodForRoute';
import routeMetadata from '@routes/routeMetadata';
import DarkModeToggle from '@shared/components/DarkModeToggle';
import Link from '@shared/components/Link';
import StatusIcon, {
  healthStatusVariants,
} from '@shared/components/StatusIcon';
import raiseToast from '@shared/components/Toast';
import ApiKeysModal from '@components/System/AccountsView/AccountView/ApiKeysModal';

import ChangePassword from './ChangePassword';
import RegistryList from './RegistryList';
import WhatsNew from './WhatsNew';
// eslint-disable-next-line
import s from './Topnav.scss';

const USER_TIMEZONE = moment.tz.guess();

const NAV_ICONS = {
  dashboard: 'dashboard',
  applications: 'th',
  artifacts: 'tags',
  policy: 'suitcase',
  reports: 'list alternate outline',
  events: 'bullhorn',
  system: 'setting',
  inventory: s.kubeIcon,
};

// Wrapper component that conditionally wraps children in a component
const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children;

// Factory that turns each entitlement into a list of <List.Item> elements
const makeEntitlementList = arr =>
  arr.map(item => {
    let el = null;
    if (item && typeof item.name === 'string' && item.name.trim().length) {
      const name = item.name.trim().toLowerCase();

      const label =
        typeof item.label === 'string' && item.label.trim().length
          ? item.label.trim()
          : name;

      const uri =
        typeof item.uri === 'string' && item.uri.trim().length
          ? item.uri.trim()
          : false;

      const desc =
        typeof item.description === 'string' && item.description.trim().length
          ? item.description.trim()
          : false;

      el = (
        <span className={classNames(s[`${name}Logo`], s.entitlementLogo)}>
          <span>{label}</span>
        </span>
      );
      if (uri) {
        el = (
          <a href={uri} target="_blank" rel="noopener noreferrer">
            {el}
          </a>
        );
      }
      el = (
        <List.Item
          className={s.listItem}
          key={!desc ? `entitlement_${name}` : null}
        >
          <Icon
            color="teal"
            size={!desc ? 'tiny' : null}
            name={`circle${desc ? ' info' : ''}`}
          />
          <List.Content className={s.listItemContent}>{el}</List.Content>
        </List.Item>
      );

      if (desc) {
        el = (
          <Popup
            trigger={el}
            className={classNames(
              s.listItemPopup,
              'animate__animated animate__fadeIn',
            )}
            key={`entitlement_${name}`}
            position="left center"
            hideOnScroll
            hoverable
            content={
              <div>
                <Header as="h4">About {label}</Header>
                <p>{desc}</p>
              </div>
            }
          />
        );
      }
    }
    return el;
  });

class Topnav extends PureComponent {
  /**
   * [description]
   * @return {[type]} [description]
   */
  showAccountSwitch = () => {
    const { auth, app } = this.props;
    const { topnav } = app.topnav.viewProps;
    const { accounts, context, accountname } = auth.account;
    const hasAccounts =
      accounts instanceof Array &&
      accounts.filter(
        item => item.state === 'enabled' || item.state === 'disabled',
      ).length > 1;

    let itemEl = context ? (
      <Dropdown.Item
        key="ctx"
        disabled={!hasAccounts}
        selected={topnav.contextitm}
        className={s.acctMenuItem}
        icon={<Icon name="shuffle" color="teal" />}
        onMouseEnter={() => {
          this.ctxpin = true;
          this.lnkpin = false;
          this.props.setTopnavViewProps('topnav', {
            contextitm: true,
            linksitm: false,
          });
        }}
        onMouseLeave={() => {
          this.ctxpin = false;
          this.lnkpin = false;
        }}
        onClick={() => {
          this.props.setTopnavViewProps('topnav', {
            contextitm: true,
            linksitm: false,
          });
        }}
        content="Switch Account Data Context"
      />
    ) : null;

    if (itemEl && hasAccounts) {
      itemEl = (
        <Popup
          trigger={itemEl}
          position="left center"
          onMouseLeave={() => {
            this.ctxpin = false;
          }}
          open={
            this.props.system.isUpdatingAccountContext === true
              ? false
              : this.ctxpin || topnav.contextitm
          }
          hoverable
          basic
          className={classNames(
            s.acctPopup,
            s.ctxPopup,
            'animate__animated animate__fadeIn',
          )}
        >
          <List selection>
            {accounts
              .filter(
                item => item.state === 'enabled' || item.state === 'disabled',
              )
              .map(item => (
                <List.Item
                  key={`account_${item.name}`}
                  className={s.acctListItem}
                  onMouseDown={() => {
                    if (
                      item.name !== context.accountname &&
                      item.state === 'enabled'
                    ) {
                      this.ctxpin = false;
                      this.lnkpin = false;
                      this.props
                        .updateAccountContext({ name: item.name })
                        .then(resp => {
                          if (
                            resp &&
                            resp.type === SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS
                          ) {
                            raiseToast({
                              toastId: `${item.name}:${resp.type}`,
                              message: (
                                <span>
                                  The account data context for the current
                                  session&nbsp;
                                  {item.name === accountname
                                    ? 'has been returned to account'
                                    : `has been switched to ${
                                        !resp.resp.data.account.context
                                          .isEnabled
                                          ? '(disabled)'
                                          : ''
                                      } account`}
                                  &nbsp;
                                  <strong>{item.name}</strong>.
                                </span>
                              ),
                              level: 'info',
                              icon:
                                item.name === accountname
                                  ? 'angle double left'
                                  : 'shuffle',
                              autoClose: 6000,
                            });
                          } else {
                            raiseToast({
                              toastId: `${item.name}:${resp.type}`,
                              message: (
                                <span>
                                  <strong>We&#39;re sorry&hellip;</strong>
                                  &nbsp;but due to a service error we were not
                                  able to make <strong>{item.name}</strong>
                                  &nbsp;the new data context.
                                </span>
                              ),
                              level: 'error',
                              autoClose: 8000,
                            });
                          }
                        });
                    } else {
                      this.ctxpin = true;
                      this.lnkpin = false;
                    }
                  }}
                >
                  <List.Icon
                    color="green"
                    name={
                      item.name === context.accountname
                        ? 'check circle'
                        : 'circle outline'
                    }
                  />
                  <List.Icon
                    style={{
                      position: 'absolute',
                      color: '#fff',
                      zIndex: 0,
                    }}
                    name="circle"
                  />
                  <List.Content className={s.acctListItemContent}>
                    <Icon
                      color={item.state === 'enabled' ? 'teal' : 'grey'}
                      name={
                        item.type === 'admin' ? 'chess queen' : 'circle user'
                      }
                    />
                    {item.type !== 'admin' ? (
                      <List.Icon
                        style={{
                          position: 'absolute',
                          color: '#fff',
                          zIndex: 0,
                        }}
                        name="circle"
                      />
                    ) : null}
                    <span>
                      <span
                        className={classNames(
                          s.acctNameItem,
                          item.name === context.accountname
                            ? s.currentCtx
                            : null,
                        )}
                      >
                        {item.name}
                      </span>
                      {item.state !== 'enabled' ? (
                        <span
                          className={classNames(
                            s.acctNameItem,
                            s.acctDisabledItem,
                          )}
                        >
                          Disabled
                        </span>
                      ) : null}
                      {item.name === accountname ? (
                        <span
                          className={classNames(
                            s.acctNameItem,
                            s.acctActualItem,
                          )}
                        >
                          Actual
                        </span>
                      ) : null}
                    </span>
                  </List.Content>
                </List.Item>
              ))}
          </List>
        </Popup>
      );
    }
    return itemEl;
  };

  /**
   * [description]
   * @return {[type]} [description]
   */
  showCustomLinks = () => {
    const { app, customLinks } = this.props;
    const { topnav } = app.topnav.viewProps;

    let itemEl = customLinks ? (
      <Dropdown.Item
        // disabled={!hasAccounts}
        key="lnk"
        selected={topnav.linksitm}
        className={s.acctMenuItem}
        icon={<Icon name="external" color="teal" />}
        onMouseEnter={() => {
          this.ctxpin = false;
          this.lnkpin = true;
          this.props.setTopnavViewProps('topnav', {
            linksitm: true,
            contextitm: false,
          });
        }}
        onMouseLeave={() => {
          this.ctxpin = false;
          this.lnkpin = false;
        }}
        onClick={() => {
          this.props.setTopnavViewProps('topnav', {
            linksitm: true,
            contextitm: false,
          });
        }}
        content={
          <span className={s.acctMenuItemTitle}>{customLinks.title}</span>
        }
      />
    ) : null;

    if (itemEl) {
      itemEl = (
        <Popup
          trigger={itemEl}
          position="left center"
          onMouseLeave={() => {
            this.lnkpin = false;
            this.ctxpin = false;
          }}
          open={this.lnkpin || topnav.linksitm}
          hoverable
          basic
          className={classNames(
            s.acctPopup,
            s.lnkPopup,
            'animate__animated animate__fadeIn',
          )}
        >
          <List selection>
            {customLinks.links.map(item => (
              <List.Item
                key={`${item.title}_${Math.random()}`}
                className={s.acctListItem}
              >
                <List.Content
                  as="a"
                  rel="noopener noreferrer"
                  href={item.uri}
                  target="_blank"
                  className={s.acctListItemContent}
                >
                  <Icon color="teal" name="external" />
                  <span>{item.title}</span>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Popup>
      );
    }
    return itemEl;
  };

  /**
   * [description]
   * @return {[type]} [description]
   */
  showInfoModal = trigger => {
    const { app, auth, isLicenseGood } = this.props;
    const { whatsNew } = app;
    const { account, uiVersion, engineVersion, dbVersion } = auth;

    const {
      id,
      type,
      user,
      email,
      expiration,
      isBeforeWarning,
      organization,
      entitlements,
    } = isLicenseGood;

    const {
      username,
      authUsername,
      authType,
      userCreatedAt,
      accountname,
      sessionStart,
      isAdmin,
      rbac,
      context,
    } = account;

    const acctCtx =
      context && context.accountname ? context.accountname : accountname;

    return (
      <Modal
        id="about_client_modal"
        className="animate__animated animate__fadeInDownBig"
        size="small"
        closeIcon
        trigger={
          trigger || (
            <Dropdown.Item
              key="info"
              className={s.acctMenuItem}
              onMouseEnter={() => {
                this.ctxpin = false;
                this.props.setTopnavViewProps('topnav', {
                  contextitm: false,
                });
              }}
              icon={<Icon name="user circle" color="teal" />}
              content="About Anchore Enterprise Client"
              data-test="about-client-modal-trigger"
            />
          )
        }
      >
        <Header
          style={{
            fontWeight: 100,
            backgroundColor: 'rgb(54, 130, 224)',
            border: 0,
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span className={s.aboutLogo} />
          <span data-test="about-client-modal" className={s.aboutTitle}>
            Enterprise Client
          </span>
        </Header>
        <Modal.Content style={{ overflow: 'hidden' }}>
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column width={8} verticalAlign="middle">
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        className={classNames(
                          'animate__animated animate__bounceInDown',
                          s.sadRobot,
                        )}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  {whatsNew && !whatsNew.isWhatsNewCtrlShown ? (
                    <Grid.Row>
                      <Grid.Column textAlign="center">
                        <Button
                          compact
                          size="tiny"
                          color="green"
                          onClick={() => {
                            this.props.setWhatsNewDialog(true);
                            this.props.setWhatsNewShowCtrl(true);
                            document
                              .getElementById('about_client_modal')
                              .firstChild.click();
                          }}
                        >
                          What&#39;s New in This Release?
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  ) : null}
                </Grid>
              </Grid.Column>
              <Grid.Column width={8}>
                <Grid>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column>
                      <Icon name="tv" color="teal" />
                      &nbsp;&nbsp;Enterprise Client Version:&nbsp;
                      <code>{uiVersion}</code>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column>
                      <Icon name="plug" color="teal" />
                      &nbsp;&nbsp;Enterprise Service Version:&nbsp;
                      <code>{engineVersion}</code>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column>
                      <Icon name="database" color="teal" />
                      &nbsp;&nbsp;Anchore DB Version:&nbsp;
                      <code>{dbVersion}</code>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column>
                      <Icon name="copyright outline" color="teal" />
                      &nbsp;&nbsp;
                      {moment().year()} Anchore Inc. All Rights Reserved
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Divider fitted horizontal className={s.divider}>
          <Icon circular inverted color="teal" name="user circle" />
        </Divider>
        <Modal.Content style={{ margin: 0, padding: '1rem' }}>
          <Grid columns={entitlements ? 2 : 1}>
            <Grid.Row verticalAlign="middle" divided>
              <Grid.Column width={entitlements ? 10 : 16}>
                <Grid columns={2}>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column width={6}>
                      <strong>User Created:</strong>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      {moment(userCreatedAt)
                        .tz(USER_TIMEZONE)
                        .format('MMMM Do YYYY')}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column width={6}>
                      <strong>Username:</strong>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <code>{authUsername}</code>
                      &nbsp;&nbsp;
                      {isAdmin ? (
                        <span>
                          <Icon name="chess queen" color="teal" />
                          <span>Administrator</span>
                        </span>
                      ) : (
                        <span>
                          <Icon name="user circle" color="teal" />
                          <span>Standard User</span>
                        </span>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column width={6}>
                      <strong>Member of Account:</strong>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <code>{accountname}</code>
                      {(() => {
                        let perms = null;
                        if (!isAdmin) {
                          perms = rbac
                            .find(item => item.account === accountname)
                            .permissions.map(permItem => (
                              <List.Item as="li" key={permItem.action}>
                                <code>
                                  {permItem.action === '*'
                                    ? 'All Permissions (Full Control)'
                                    : permItem.action}
                                </code>
                              </List.Item>
                            ));
                          perms = (
                            <Popup
                              content={
                                <div>
                                  <div>
                                    Listed below are the permissions granted for
                                    use within account&nbsp;
                                    <code>
                                      <strong>{accountname}</strong>
                                    </code>
                                    :
                                  </div>
                                  <List as="ul">{perms}</List>
                                </div>
                              }
                              position="right center"
                              trigger={
                                <span className={s.viewPermsLink}>
                                  View Permissions
                                </span>
                              }
                            />
                          );
                        }
                        return perms;
                      })()}
                    </Grid.Column>
                  </Grid.Row>
                  {acctCtx !== accountname ? (
                    <Grid.Row className={s.aboutItem}>
                      <Grid.Column width={6}>
                        <strong>Current Account Context:</strong>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <code>{acctCtx}</code>
                        {(() => {
                          let perms = null;
                          if (!isAdmin) {
                            perms = rbac
                              .find(item => item.account === acctCtx)
                              .permissions.map(permItem => (
                                <List.Item as="li" key={permItem.action}>
                                  <code>{permItem.action}</code>
                                </List.Item>
                              ));
                            perms = (
                              <Popup
                                content={
                                  <div>
                                    <div>
                                      Listed below are the permissions granted
                                      for use within account&nbsp;
                                      <code>
                                        <strong>{acctCtx}</strong>
                                      </code>
                                      :
                                    </div>
                                    <List as="ul">{perms}</List>
                                  </div>
                                }
                                position="right center"
                                trigger={
                                  <span className={s.viewPermsLink}>
                                    View Permissions
                                  </span>
                                }
                              />
                            );
                          }
                          return perms;
                        })()}
                      </Grid.Column>
                    </Grid.Row>
                  ) : null}
                  {authUsername !== username && authType !== 'jwt' ? (
                    <Grid.Row className={s.aboutItem}>
                      <Grid.Column width={6}>
                        <strong>User Mapping:</strong>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <code>{username}</code>
                        <Popup
                          position="right center"
                          trigger={
                            <Icon
                              className={s.mappingInfoIcon}
                              color="orange"
                              name="question circle"
                            />
                          }
                          content={
                            <span>
                              User <strong>{authUsername}</strong> is managed by
                              an external directory server, but is associated
                              with the analysis data stored in an Anchore
                              Enterprise Services account&nbsp;
                              <strong>{accountname}</strong> by user
                              mapping&nbsp;
                              <strong>{username}</strong>.
                            </span>
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  ) : null}
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column width={6}>
                      <strong>Current Session Started:</strong>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      {moment(sessionStart)
                        .tz(USER_TIMEZONE)
                        .format('ddd, DD MMM YYYY [at] HH:mm:ss z')}{' '}
                      ({moment(sessionStart).fromNow()})
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              {entitlements ? (
                <Grid.Column width="6" className={s.entitlementsCol}>
                  <List className={s.listWrapperItem}>
                    <List.Header className={s.listHeaderItem}>
                      Entitlements
                    </List.Header>
                    {makeEntitlementList(entitlements)}
                  </List>
                  <span className={s.spaceMan} />
                  <span className={s.cameraTripod} />
                </Grid.Column>
              ) : null}
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Divider fitted horizontal className={s.divider}>
          <Icon circular inverted color="teal" name="drivers license" />
        </Divider>
        <Modal.Content style={{ margin: 0, padding: '1rem' }}>
          <Grid columns={2}>
            <Grid.Row className={s.aboutItem}>
              <Grid.Column width={3}>
                <strong>License ID:</strong>
              </Grid.Column>
              <Grid.Column width={13}>
                <code>{id}</code>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={s.aboutItem}>
              <Grid.Column width={3}>
                <strong>License Type:</strong>
              </Grid.Column>
              <Grid.Column width={13}>
                <code>{`${type.charAt(0).toUpperCase()}${type.substr(
                  1,
                )}`}</code>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={s.aboutItem}>
              <Grid.Column width={3}>
                <strong>Expires:</strong>
              </Grid.Column>
              <Grid.Column width={13}>
                {moment(expiration)
                  .tz(USER_TIMEZONE)
                  .format('ddd, DD MMM YYYY [at] HH:mm:ss z')}
                &nbsp;(
                {moment(expiration).fromNow()})
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={s.aboutItem}>
              <Grid.Column width={3}>
                <strong>Licensed to:</strong>
              </Grid.Column>
              <Grid.Column width={13}>
                {user}
                &nbsp;
                <a href={`mailto:${email}`}>
                  <Icon name="mail" color="teal" />
                  {email}
                </a>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={s.aboutItem}>
              <Grid.Column width={3}>
                <strong>Organization:</strong>
              </Grid.Column>
              <Grid.Column width={13}>{organization}</Grid.Column>
            </Grid.Row>
          </Grid>

          {!isBeforeWarning && (
            <Segment
              size="tiny"
              inverted
              secondary
              color="red"
              textAlign="center"
              className="animate__animated animate__pulse"
            >
              <Icon name="warning sign" />
              Your license will expire&nbsp;
              <strong>{moment(expiration).fromNow()}</strong>
              —contact Anchore Support for a renewal!
            </Segment>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            as="a"
            size="tiny"
            href="https://support.anchore.com"
            icon
            color="blue"
            target="_blank"
            labelPosition="right"
            style={{ margin: 0 }}
          >
            <Icon name="external alternate" />
            Visit Anchore Support
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  /**
   * [description]
   * @param  {[type]} trigger [description]
   * @return {[type]}         [description]
   */
  showPwdModal = trigger => {
    const { auth } = this.props;
    const { account } = auth;

    return (
      <Modal
        size="mini"
        closeIcon
        trigger={
          trigger || (
            <Dropdown.Item
              key="pwd"
              disabled={account.authType !== 'default'}
              className={s.acctMenuItem}
              icon={<Icon name="lock" color="teal" />}
              content="Change Your Password"
              onMouseEnter={() => {
                this.ctxpin = false;
                this.lnkpin = false;
                this.props.setTopnavViewProps('topnav', {
                  contextitm: false,
                  linksitm: false,
                });
              }}
            />
          )
        }
      >
        <Header
          style={{
            fontWeight: 100,
            backgroundColor: 'rgb(54, 130, 224)',
            border: 0,
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span className={s.aboutTitle}>Change Your Password</span>
        </Header>
        <Modal.Content>
          <Modal.Description>
            <ChangePassword />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  };

  showRegistryModal = trigger => {
    const { permissions } = this.props;

    return (
      <RegistryList
        permissions={permissions}
        trigger={
          trigger || (
            <Dropdown.Item
              key="registry"
              disabled={!permissions.listRegistries}
              className={s.acctMenuItem}
              icon={<Icon name="address book outline" color="teal" />}
              content="Add / Edit Registry Credentials"
              onMouseEnter={() => {
                this.ctxpin = false;
                this.lnkpin = false;
                this.props.setTopnavViewProps('topnav', {
                  contextitm: false,
                  linksitm: false,
                });
              }}
              onClick={() => {
                this.props.setTopnavViewProps('topnav', {
                  registryitm: true,
                });
              }}
            />
          )
        }
      />
    );
  };

  /**
   * [render description]
   * @return {[type]} [description]
   */
  render() {
    const { auth, isLicenseGood, app, whatsNew, uiVersion, systemStatus } =
      this.props;
    const { navbtns, accountbtn } = app.topnav.viewProps.topnav;
    const { healthCheck } = app;
    const { isEngineVersionGood } = healthCheck;
    const engineVersion = isEngineVersionGood?.engineVersion;
    const { isAuthenticated, account } = auth;
    const isLdapUser = account.authType === 'ldap';

    const isSwitched =
      account &&
      account.context &&
      account.context.accountname !== account.accountname;

    return (
      <div className={s.root}>
        <Container>
          <Menu
            inverted
            borderless
            fixed="top"
            className={classNames(
              s.topNavMenu,
              isAuthenticated ? 'pace-border' : '',
            )}
          >
            <Menu.Item as="a" header href="/" className={s.logoWrapper}>
              <span className={s.logo} />
              <span
                className={classNames(
                  s.releaseLabel,
                  'animate__animated',
                  'animate__fadeIn',
                )}
              >
                {engineVersion ? `${engineVersion} / ` : null}
                {uiVersion} UI
              </span>
            </Menu.Item>
            {isAuthenticated && whatsNew ? <WhatsNew data={whatsNew} /> : null}
            {isAuthenticated &&
            isLicenseGood &&
            !isLicenseGood.isBeforeWarning ? (
              <Menu.Item header>
                {this.showInfoModal(
                  <div
                    className={classNames(
                      'animate__animated animate__fadeIn',
                      s.licenseWarningWrapper,
                    )}
                  >
                    <Icon color="orange" name="warning sign" />
                    <span>
                      Your license will expire&nbsp;
                      {moment(isLicenseGood.expiration).fromNow()}
                    </span>
                  </div>,
                )}
              </Menu.Item>
            ) : null}
            {(() => {
              let navBtnGrpEl = null;
              if (isAuthenticated && navbtns) {
                const fadeCnd = window.scrollY ? 'animate__fadeOut' : 'hide';
                navBtnGrpEl = (
                  <Menu.Item header id="navBtnGrp">
                    <div
                      className={classNames(
                        s.shield,
                        navbtns === 'down' ? 'hide' : null,
                      )}
                    />
                    {routeMetadata
                      .filter(
                        item =>
                          item.navbar &&
                          item.navbar.show !== false &&
                          !item.featureFlag,
                      )
                      .map((item, idx) => {
                        const enabled = arePermsGoodForRoute({
                          permissions: getUserPermsSync({
                            account,
                            actions: item.navbar.permissions,
                          }),
                          route: item,
                        });

                        // Add a notification icon to the minimized nav control
                        // button when and where applicable
                        let notifyIcon = null;

                        if (
                          item.navbar.name === 'system' &&
                          enabled &&
                          this.props.active !== item.navbar.title &&
                          account.isAdmin
                        ) {
                          notifyIcon = (
                            <StatusIcon
                              className={s.notifyIcon}
                              fitted
                              size="small"
                              variant={healthStatusVariants[systemStatus]}
                              disabled={!enabled}
                            />
                          );
                        }

                        const itemContentEl = (
                          <div
                            className={classNames(
                              s.linkBtn,
                              'animate__animated',
                              navbtns === 'down' ? 'animate__fadeIn' : fadeCnd,
                            )}
                            style={{
                              cursor:
                                navbtns === 'down' ? 'pointer' : 'default',
                              animationDelay:
                                navbtns === 'down'
                                  ? `${(idx + 1) * 50}ms`
                                  : `0ms`,
                            }}
                          >
                            <Label
                              basic
                              disabled={!enabled}
                              className={classNames(
                                s.topNavBtn,
                                this.props.active === item.navbar.title
                                  ? s.active
                                  : null,
                              )}
                            >
                              <Icon.Group>
                                <Icon
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                  fitted
                                  disabled={!enabled}
                                  className={NAV_ICONS[item.navbar.name]}
                                />
                                {notifyIcon}
                              </Icon.Group>
                            </Label>
                          </div>
                        );

                        let shouldLink = true;

                        // Inventory shouldn't link if user is on path already
                        if (
                          item.navbar.name === 'inventory' &&
                          window.location.pathname === item.path
                        ) {
                          shouldLink = false;
                        }

                        const itemEl =
                          enabled && shouldLink ? (
                            <Link to={item.path} key={item.navbar.name}>
                              {itemContentEl}
                            </Link>
                          ) : (
                            itemContentEl
                          );

                        return (
                          <Popup
                            key={item.navbar.name}
                            position="bottom center"
                            className="animate__animated animate__fadeIn"
                            hideOnScroll
                            trigger={itemEl}
                            content={
                              <span>
                                {item.navbar.title}
                                {!enabled ? (
                                  <>
                                    &nbsp;<strong>(Disabled)</strong>
                                  </>
                                ) : (
                                  ''
                                )}
                              </span>
                            }
                          />
                        );
                      })}
                  </Menu.Item>
                );
              }
              return navBtnGrpEl;
            })()}

            {isAuthenticated && account ? (
              <Menu.Menu position="right">
                <DarkModeToggle />
                {isSwitched ? (
                  <Menu.Item style={{ overflow: 'hidden' }}>
                    <Button
                      icon
                      labelPosition="left"
                      className={s.accountBtn}
                      color="orange"
                      size="tiny"
                      disabled={this.props.system.isUpdatingAccountContext}
                      onClick={() => {
                        this.props
                          .updateAccountContext({
                            name: account.accountname,
                          })
                          .then(resp => {
                            if (
                              resp &&
                              resp.type === SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS
                            ) {
                              raiseToast({
                                toastId: `${account.accountname}:${resp.type}`,
                                message: (
                                  <span>
                                    The account data context for the current
                                    session has been has been returned to
                                    account&nbsp;
                                    <strong>{account.accountname}</strong>.
                                  </span>
                                ),
                                level: 'info',
                                icon: 'angle double left',
                                autoClose: 6000,
                                dismissAll: true,
                              });
                            } else {
                              raiseToast({
                                toastId: `${account.accountname}:${resp.type}`,
                                message: (
                                  <span>
                                    <strong>We&#39;re sorry&hellip;</strong>
                                    &nbsp;but due to a service error we were not
                                    able to make&nbsp;
                                    <strong>{account.accountname}</strong>
                                    &nbsp;the new data context.
                                  </span>
                                ),
                                level: 'error',
                                autoClose: 8000,
                                dismissAll: true,
                              });
                            }
                          });
                      }}
                    >
                      <span style={{ fontWeight: 500, marginRight: '0.25rem' }}>
                        Return to context
                      </span>
                      <strong>{account.accountname}</strong>
                      <Icon name="angle double left" />
                    </Button>
                  </Menu.Item>
                ) : null}
                {account.username ? (
                  <Menu.Item style={{ overflow: 'hidden' }}>
                    <Label
                      as="a"
                      onClick={() => {
                        raiseToast(false);
                        if (!this.ctxpin) {
                          this.props.setTopnavViewProps('topnav', {
                            accountbtn: !accountbtn,
                            contextitm: false,
                            linksitm: false,
                          });
                        }
                      }}
                      className={s.accountBtn}
                      color={account && account.isAdmin ? 'violet' : 'blue'}
                      data-test="topnav-account-pulldown"
                    >
                      <span
                        className={
                          account && account.isAdmin ? s.rocket : s.robot
                        }
                      />
                      <span
                        style={{
                          paddingLeft: '1.25rem',
                          color: 'rgba(255,255,255,0.85)',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {account && account.isAdmin ? (
                          <Icon
                            name="chess queen"
                            className={s.accountBtnAdminIcon}
                          />
                        ) : null}
                        <span
                          style={{
                            fontWeight: 500,
                            color: 'rgba(255,255,255,0.85)',
                            lineHeight: '2rem',
                          }}
                        >
                          Account
                        </span>
                        &nbsp;
                        <span style={{ lineHeight: '2rem' }}>
                          {account.accountname}
                        </span>
                      </span>
                      {account.context &&
                      (account.context.accountname !== account.accountname ||
                        this.props.system.isUpdatingAccountContext) ? (
                        <>
                          <span className={s.verticalDivider} />
                          <span
                            style={{
                              fontWeight: 500,
                              color: 'rgba(255,255,255,0.85)',
                              lineHeight: '2rem',
                            }}
                          >
                            Context
                          </span>
                          &nbsp;
                          {this.props.system.isUpdatingAccountContext ? (
                            <Loader
                              size="tiny"
                              active
                              inline
                              inverted
                              style={{
                                marginLeft: '0.25rem',
                                lineHeight: '2rem',
                              }}
                            />
                          ) : (
                            <>
                              <span style={{ lineHeight: '2rem' }}>
                                {account.context.accountname}
                              </span>
                              {!account.context.isEnabled ? (
                                <span
                                  style={{
                                    fontWeight: 500,
                                    color: 'rgba(255,255,255,0.85)',
                                    marginLeft: '0.25rem',
                                    lineHeight: '2rem',
                                  }}
                                >
                                  (disabled)
                                </span>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : null}
                      <span className={s.verticalDivider} />
                      <span
                        style={{
                          fontWeight: 500,
                          color: 'rgba(255,255,255,0.85)',
                          lineHeight: '2rem',
                        }}
                      >
                        Username
                      </span>
                      &nbsp;
                      <span style={{ lineHeight: '2rem' }}>
                        {account.authUsername}
                      </span>
                      <Icon
                        name={`caret ${accountbtn ? 'up' : 'down'}`}
                        className={s.accountBtnCaretIcon}
                      />
                    </Label>
                  </Menu.Item>
                ) : null}
                <Dropdown
                  open={
                    this.props.system.isUpdatingAccountContext === true
                      ? false
                      : this.ctxpin || this.lnkpin || accountbtn
                  }
                  icon={false}
                  style={{ padding: 0, width: 0, fontSize: '0.95rem' }}
                  item
                  closeOnChange={false}
                  onClose={() => {
                    if (!this.ctxpin) {
                      setTimeout(() => {
                        this.props.setTopnavViewProps('topnav', {
                          accountbtn: false,
                          contextitm: false,
                          linksitm: false,
                        });
                        this.ctxpin = false;
                      }, 0);
                    }
                  }}
                >
                  <Dropdown.Menu
                    style={{ borderTop: 'none', borderBottomRightRadius: 0 }}
                    className={classNames(
                      'animate__animated animate__fadeIn',
                      s.acctMenu,
                    )}
                  >
                    <Dropdown.Divider style={{ margin: 0 }} />
                    {this.showInfoModal()}
                    <Dropdown.Divider style={{ margin: 0 }} />
                    {this.showAccountSwitch()}
                    {this.showRegistryModal()}
                    {this.showCustomLinks()}
                    {this.showPwdModal()}
                    <ConditionalWrap
                      condition={isLdapUser}
                      wrap={children => (
                        <Popup trigger={children} position="right center">
                          API key creation and management is only available to
                          non-LDAP users.
                        </Popup>
                      )}
                    >
                      <Dropdown.Item
                        content={
                          <span className={s.apiKeysMenuItem}>
                            API Keys
                            {isLdapUser && (
                              <Icon
                                name="info circle"
                                color="orange"
                                className={s.infoIcon}
                              />
                            )}
                          </span>
                        }
                        className={s.acctMenuItem}
                        icon={<Icon name="key" color="teal" />}
                        key="api-keys"
                        disabled={isLdapUser}
                        onMouseEnter={() => {
                          this.ctxpin = false;
                          this.lnkpin = false;
                          this.props.setTopnavViewProps('topnav', {
                            contextitm: false,
                            linksitm: false,
                          });
                        }}
                        onClick={() =>
                          !isLdapUser &&
                          this.props.showModal({
                            type: 'apiKeys',
                            props: {
                              account: account.accountname,
                              userName: account.username,
                            },
                          })
                        }
                      />
                    </ConditionalWrap>
                    <Dropdown.Item
                      key="docs"
                      className={s.acctMenuItem}
                      icon={<Icon name="book" color="teal" />}
                      content="Documentation"
                      onMouseEnter={() => {
                        this.ctxpin = false;
                        this.lnkpin = false;
                        this.props.setTopnavViewProps('topnav', {
                          contextitm: false,
                          linksitm: false,
                        });
                      }}
                      onClick={() => {
                        window.open('https://docs.anchore.com', '_blank');
                      }}
                    />
                    <Dropdown.Item
                      key="logout"
                      className={s.acctMenuItem}
                      icon={<Icon name="sign out" color="teal" />}
                      content="Log Out"
                      onMouseEnter={() => {
                        this.ctxpin = false;
                        this.lnkpin = false;
                        this.props.setTopnavViewProps('topnav', {
                          contextitm: false,
                          linksitm: false,
                        });
                      }}
                      onClick={() => {
                        this.props.logout({
                          server: true,
                        });
                      }}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            ) : null}
          </Menu>
          <ApiKeysModal permissions={this.props.permissions} />
        </Container>
      </div>
    );
  }
}

export const propTypes = {
  permissions: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      createRegistry: PropTypes.bool,
      updateRegistry: PropTypes.bool,
      deleteRegistry: PropTypes.bool,
    }),
  ]).isRequired,
  customLinks: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
    .isRequired,
  app: PropTypes.shape({
    whatsNew: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
    topnav: PropTypes.shape({
      viewProps: PropTypes.shape({
        topnav: PropTypes.shape({
          navbtns: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
          contextitm: PropTypes.bool,
          linksitm: PropTypes.bool,
          accountbtn: PropTypes.bool,
          registryitm: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
          registryDialogOnClose: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.func,
          ]),
        }),
      }),
    }),
    healthCheck: PropTypes.shape({
      isEngineVersionGood: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
          engineVersion: PropTypes.string,
          uiVersion: PropTypes.string,
          valid: PropTypes.bool,
        }),
      ]),
    }),
  }).isRequired,
  system: PropTypes.shape({
    isUpdatingAccountContext: PropTypes.bool,
  }).isRequired,
  active: PropTypes.string,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    dbVersion: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    engineVersion: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    uiVersion: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    account: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
    permissions: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        createRegistry: PropTypes.bool,
        updateRegistry: PropTypes.bool,
        deleteRegistry: PropTypes.bool,
      }),
    ]),
  }).isRequired,
  logout: PropTypes.func.isRequired,
  updateAccountContext: PropTypes.func.isRequired,
  setTopnavViewProps: PropTypes.func.isRequired,
  setWhatsNewDialog: PropTypes.func.isRequired,
  setWhatsNewShowCtrl: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  isLicenseGood: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
    .isRequired,
  whatsNew: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
    .isRequired,
  uiVersion: PropTypes.string.isRequired,
  systemStatus: PropTypes.string,
};

Topnav.propTypes = propTypes;

Topnav.defaultProps = {
  active: undefined,
  systemStatus: undefined,
};

export default withStyles(s)(Topnav);
