import StyleContext from 'isomorphic-style-loader/StyleContext';
import moment from 'moment-timezone';
import queryString from 'query-string';
import deepForceUpdate from 'react-deep-force-update';
import ReactDOM from 'react-dom';

import { FeatureFlagProvider } from '@shared/components/FeatureFlag';

import { updateMeta } from './DOMUtils';
import App from './components/App';
import history from './history';
import { getUserPerms } from './rbac';
import router from './router';

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: (...styles) => {
    // eslint-disable-next-line no-underscore-dangle
    const removeCss = styles.map(x => x._insertCss());
    return () => {
      removeCss.forEach(f => f());
    };
  },
  // RBAC user permission resolution
  getUserPerms,
};

const container = document.getElementById('app');
let currentLocation = history;
let appInstance;

// Switch off the native scroll restoration behavior and handle it manually
// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
const scrollPositionsHistory = {};
if (window.history && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

// Re-render the app when window.location changes. Provide defaults if the props
// weren't passed (this can happen after a hot-reload)
async function onLocationChange({
  location = history.location,
  action = history.action,
}) {
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }

  currentLocation = location;

  const isInitialRender = !action;
  try {
    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve({
      ...context,
      pathname: location.pathname,
      query: queryString.parse(location.search),
      apiUrl: window.App.apiUrl || false,
      isAppConnectionHealthy: window.App.isAppConnectionHealthy || false,
      isAuthenticated: !!window.App.isAuthenticated,
      dbVersion: window.App.dbVersion || false,
      engineVersion: window.App.engineVersion || false,
      uiVersion: window.App.uiVersion || false,
      isProduction: window.App.isProduction || false,
      isEngineHealthy: window.App.isEngineHealthy || false,
      isAppDBHealthy: window.App.isAppDBHealthy || false,
      isEngineVersionGood: window.App.isEngineVersionGood || false,
      isLicenseGood: window.App.isLicenseGood || false,
      isReportsEnabledAndActive: window.App.isReportsEnabledAndActive || {
        enabled: false,
        active: false,
      },
      isLdapEnabled: window.App.isLdapEnabled || false,
      isSsoAuthOnly: window.App.isSsoAuthOnly || false,
      account: window.App.account || false,
      customLinks: window.App.customLinks || false,
      whatsNew: window.App.whatsNew || false,
      isWebSocketOnly: window.App.isWebSocketOnly || false,
      customMessage: window.App.customMessage || false,
      enrichInventoryView: window.App.enrichInventoryView ?? true,
      featureFlags: window.App.featureFlags,
      anchoreInternalDeployment: window.App.anchoreInternalDeployment ?? false,
    });

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }

    const renderReactApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;
    appInstance = renderReactApp(
      <FeatureFlagProvider flags={window.App.featureFlags || []}>
        <StyleContext.Provider value={{ insertCss: context.insertCss }}>
          <App context={context}>{route.component}</App>
        </StyleContext.Provider>
      </FeatureFlagProvider>,
      container,
      () => {
        if (isInitialRender) {
          const elem = document.getElementById('css');
          if (elem) elem.parentNode.removeChild(elem);
          return;
        }

        document.title = route.title;
        updateMeta('description', route.description);

        let scrollX = 0;
        let scrollY = 0;
        const pos = scrollPositionsHistory[location.key];
        if (pos) {
          scrollX = pos.scrollX;
          scrollY = pos.scrollY;
        } else {
          const targetHash = location.hash.substr(1);
          if (targetHash) {
            const target = document.getElementById(targetHash);
            if (target) {
              scrollY = window.pageYOffset + target.getBoundingClientRect().top;
            }
          }
        }

        // Restore the scroll position if it was saved into the state
        // or scroll to the given #hash anchor
        // or scroll to top of the page
        window.scrollTo(scrollX, scrollY);
      },
    );
  } catch (error) {
    if (__DEV__) {
      throw error;
    }

    console.error(error);

    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation.key === location.key) {
      window.location.reload();
    }
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history.listen(onLocationChange);
onLocationChange(currentLocation);

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance);
    }

    onLocationChange(currentLocation);
  });
}

if (!__DEV__) {
  console.info(
    `%c
                     ..--:///:--..
                 \`.:+++++++++++++++:.\`
               \`:+++++++++++++++++++++:\`
              .+++++++/::.....::/+++++++.
             -++++++/-           -/++++++.
             /+++++/\`             \`/++++++
             \`:///:                \`++++++-
                                    ++++++-
                                  \`.++++++-
                           \`\`..://++++++++-  ╭───────────────────────────────────╮
                   \`\`..:://+++++++++++++++-  │ Anchore Enterprise Client v${
                     window.App.uiVersion
                   } │
                .-/+++++++++++++++//++++++-  ╰───────────────────────────────────╯
              -/+++++++++++/::..\`\`  ++++++-
             :+++++++/-..\`\`         ++++++-
            .++++++:\`               ++++++-
            -++++++                 ++++++-
            -++++++                 ++++++-
            \`++++++/\`               ++++++-
             -++++++/:\`             ./++++-
              ./+++++++///::\`        \`:+++-
                ./+++++++++++          \`.:.
                  \`-:/++++++/
                       \`.... © Anchore Inc. ${moment().year()}. All Rights Reserved.
  `,
    'color: rgb(54, 130, 224);text-shadow: 1px 1px rgba(0, 0, 0, 0.125);',
  );
}
