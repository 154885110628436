import type { AsyncThunk, Draft } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import request, {
  type Response,
  type SuperAgentRequest,
  type SuperAgentStatic,
} from 'superagent';

import type {
  EntityActionParams,
  EntityServiceResponse,
  ServiceError,
} from '@models';
import raiseToast from '@shared/components/Toast';

import type { AppDispatch, RootState } from '../store';

type RestVerb = 'get' | 'post' | 'put' | 'delete' | 'patch';

export type EntityReducerOptions<State = any> = {
  pending?: (state: Draft<State>, action: any) => void;
  rejected?: (state: Draft<State>, action: any) => void;
  fulfilled?: (state: Draft<State>, action: any) => void;
};

export type EntityConfig<Params> = {
  sliceName?: string;
  stateKey: string;
  verb?: RestVerb;
  servicePath: (params: Params) => string;
  condition?: (state: RootState) => boolean;
  customRequest?: (
    req: SuperAgentStatic,
    path: string,
    params: Params,
  ) => SuperAgentRequest;
  toastOnSuccess?: (params: Params, resp: any) => string | JSX.Element;
  toastOnFailure?: (params: Params, error?: any) => string | JSX.Element;
  getToastId?: (params: Params) => string;
  actionDescription?: string;
  actionName?: string;
  onSuccess?: (thunkApi: GetThunkAPI<any>) => void;
  reducerOptions?: EntityReducerOptions;
};

export type CreateEntityActionOptions<Actions> = {
  [K in keyof Actions]: {
    thunk: any;
    stateKey: string;
  } & EntityReducerOptions;
};

const defaultRequest = <Params extends EntityActionParams | void>(
  verb: RestVerb,
  path: string,
  params: Params,
) =>
  params && (verb === 'post' || verb === 'put' || verb === 'patch')
    ? request[verb](path).send(params)
    : request[verb](path);

const setHeaders = (req: SuperAgentRequest) => {
  if (window.sock?.id) return req.set('anchore-socketid', window.sock?.id);
  return req;
};

export const createEntityAction = <
  Params extends EntityActionParams | void,
  Return extends EntityServiceResponse<any>,
>(
  config: EntityConfig<Params>,
): {
  thunk: AsyncThunk<
    Return,
    Params,
    {
      state: RootState;
      dispatch: AppDispatch;
      rejectValue: ServiceError;
    }
  >;
  stateKey: string;
} => {
  const {
    servicePath,
    condition,
    customRequest,
    stateKey,
    sliceName,
    verb = 'get',
    toastOnSuccess,
    toastOnFailure,
    getToastId,
    actionDescription,
    actionName,
    onSuccess,
    reducerOptions,
  } = config;
  const fetch = createAsyncThunk<
    Return,
    Params,
    {
      state: RootState;
      dispatch: AppDispatch;
      rejectValue: ServiceError;
    }
  >(
    actionName || `${sliceName ? `${sliceName}/` : ''}${stateKey}/${verb}`,
    async (params, thunkAPI) => {
      try {
        const path = servicePath(params);
        const req = customRequest
          ? customRequest(request, path, params)
          : defaultRequest(verb, path, params);
        const resp = await setHeaders(req);

        if (toastOnSuccess && (params?.showToastOnSuccess ?? true)) {
          const toastId = getToastId ? `-${getToastId?.(params)}` : '';
          raiseToast({
            toastId: `${stateKey}-${verb}-success${toastId}`,
            message: toastOnSuccess(params, resp.body),
            level: 'success',
            icon: 'save',
            autoClose: 8000,
            dismissAll: true,
          });
        }
        if (onSuccess) {
          onSuccess(thunkAPI);
        }
        return resp.body;
      } catch (error: any) {
        const resp: Response = error.response;
        if (resp) {
          const contentType = resp?.headers['content-type'];
          const statusCode = resp?.statusCode;
          const data = resp.body?.data;
          const errorObject =
            statusCode === 524
              ? {
                  message:
                    'The connection was timed out between the browser and the web service',
                }
              : contentType.includes('application/json')
                ? data || error
                : error;
          if (toastOnFailure && (params?.showToastOnFailure ?? true)) {
            const toastId = getToastId ? `-${getToastId?.(params)}` : '';
            raiseToast({
              toastId: `${stateKey}-${verb}-failure${toastId}`,
              title: actionDescription
                ? `${actionDescription} Failed`
                : undefined,
              message: toastOnFailure(params, errorObject),
              level: 'error',
              icon: 'warning sign',
              autoClose: 8000,
              dismissAll: true,
            });
          }

          return thunkAPI.rejectWithValue(errorObject);
        }
        throw error;
      }
    },
    {
      condition: (_, thunkAPI) => {
        const state = thunkAPI.getState();
        return condition ? condition(state) : true;
      },
      dispatchConditionRejection: true,
    },
  );
  return { thunk: fetch, stateKey: config.stateKey, ...reducerOptions };
};

export default createEntityAction;
