import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import merge from 'deepmerge';

import {
  type ViewTypes,
  type ViewType,
  type ViewState,
  initialTableViewProps,
} from '@models';

export type ViewsState = ViewTypes;

export const initialState: ViewsState = {
  systemIntegrationsList: initialTableViewProps,
};

const viewsSlice = createSlice({
  name: 'views',
  initialState,
  reducers: {
    setViewState: {
      reducer<T extends ViewType = ViewType>(
        state: ViewsState,
        action: PayloadAction<{ type: T; state: Partial<ViewState<T>> }>,
      ) {
        const { type, state: viewState } = action.payload;
        state[type] = merge(state[type] || {}, viewState);
      },
      prepare<T extends ViewType = ViewType>(
        type: T,
        state: Partial<ViewState<T>>,
      ) {
        return {
          payload: {
            type,
            state,
          },
        };
      },
    },
  },
});

export const { setViewState } = viewsSlice.actions;

export default viewsSlice.reducer;
