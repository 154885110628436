import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import _debounce from 'lodash/debounce';
import { matchSorter } from 'match-sorter';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Checkbox,
  Dimmer,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Loader,
  Modal,
  Popup,
} from 'semantic-ui-react';

import { hideAllExceptTopModal } from '@/DOMUtils';
import type { CreateRegistryActionParams } from '@models';
import {
  addNewRegistry,
  clearRegistryAddError,
  clearRegistryRemoveError,
  clearRegistryUpdateError,
  fetchRegistries,
  removeRegistry,
  setRegistryRemoveDialog,
  setSystemViewProps,
  updateRegistry,
} from '@redux/actions/action-system';
import { setTopnavViewProps } from '@redux/actions/action-topnav';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import CancelButton from '@shared/components/CancelButton';
import Err from '@shared/components/Err';
import ErrMessage from '@shared/components/ErrMessage';
import ReactTable from '@shared/components/ReactTable';
import NoData from '@shared/components/Table/NoData';
import TableInfo from '@shared/components/Table/TableInfo';

import s from './RegistryList.scss';

const USER_TIMEZONE = moment.tz.guess();

const regTypes = [
  { text: 'docker_v2', value: 'docker_v2' },
  { text: 'awsecr', value: 'awsecr' },
];

const awsOptions = [
  {
    key: 'aws-api-keys',
    title: 'API Keys',
    content: {
      key: 'aws-api-keys-content',
      content: (
        <>
          <div>
            Provide Anchore Enterprise with access/secret keys from an account
            or IAM user. We highly recommend using a dedicated IAM user with
            specific access restrictions for this mode.
          </div>
          <List ordered className={s.helpList}>
            <List.Item>
              <List.Header>Registry</List.Header>
              <List.Description>
                The ECR endpoint hostname (Ex.
                123456789012.dkr.ecr.us-east-1.amazonaws.com)
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Type</List.Header>
              <List.Description>
                Set to <strong>awsecr</strong>
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Username</List.Header>
              <List.Description>AWS access key</List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Password</List.Header>
              <List.Description>AWS secret key</List.Description>
            </List.Item>
          </List>
        </>
      ),
    },
  },
  {
    key: 'aws-local-creds',
    title: 'Local Credentials',
    content: {
      key: 'aws-local-creds-content',
      content: (
        <>
          <div>
            Uses the AWS credentials found in local execution environment for
            Anchore Enterprise (Ex. env vars, ~/.aws/credentials, or instance
            profile).
          </div>
          <List ordered className={s.helpList}>
            <List.Item>
              <List.Header>Registry</List.Header>
              <List.Description>
                The ECR endpoint hostname (Ex.
                123456789012.dkr.ecr.us-east-1.amazonaws.com)
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Type</List.Header>
              <List.Description>
                Set to <strong>awsecr</strong>
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Username</List.Header>
              <List.Description>
                Set to <strong>awsauto</strong>
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Password</List.Header>
              <List.Description>
                Set to <strong>awsauto</strong>
              </List.Description>
            </List.Item>
          </List>
        </>
      ),
    },
  },
  {
    key: 'aws-assume-role',
    title: 'ECR Assume Role',
    content: {
      key: 'aws-assume-role-content',
      content: (
        <>
          <div>
            To have Anchore Enterprise assume a specific role different from the
            role it currently runs within, specify a different role ARN. Anchore
            Enterprise will use the execution role (as in iamauto mode from the
            instance/task profile) to assume a different role. The execution
            role must have permissions to assume the role requested.
          </div>
          <List ordered className={s.helpList}>
            <List.Item>
              <List.Header>Registry</List.Header>
              <List.Description>
                The ECR endpoint hostname (Ex.
                123456789012.dkr.ecr.us-east-1.amazonaws.com)
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Type</List.Header>
              <List.Description>
                Set to <strong>awsecr</strong>
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Username</List.Header>
              <List.Description>
                Set to <strong>_iam_role</strong>
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Password</List.Header>
              <List.Description>The desired role’s ARN</List.Description>
            </List.Item>
          </List>
        </>
      ),
    },
  },
];

const azureOptions = [
  {
    key: 'azure-admin-account',
    title: 'Admin Account',
    content: {
      key: 'azure-admin-account-content',
      content: (
        <List ordered className={s.helpList}>
          <List.Item>
            <List.Header>Registry</List.Header>
            <List.Description>
              The login server (Ex. myregistry1.azurecr.io)
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Type</List.Header>
            <List.Description>
              Set to <strong>docker_v2</strong>
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Username</List.Header>
            <List.Description>
              The username in the &#39;az acr credentials show --name
              &#60;registry name&#62;&#39; output
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Password</List.Header>
            <List.Description>
              The password or password2 value from the &#39;az acr credentials
              show&#39; command result
            </List.Description>
          </List.Item>
        </List>
      ),
    },
  },
  {
    key: 'azure-service-principal',
    title: 'Service Principal',
    content: {
      key: 'azure-service-principal-content',
      content: (
        <List ordered className={s.helpList}>
          <List.Item>
            <List.Header>Registry</List.Header>
            <List.Description>
              The login server (Ex. myregistry1.azurecr.io)
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Type</List.Header>
            <List.Description>
              Set to <strong>docker_v2</strong>
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Username</List.Header>
            <List.Description>The service principal app id</List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Password</List.Header>
            <List.Description>The service principal password</List.Description>
          </List.Item>
        </List>
      ),
    },
  },
];

const registryOptions = [
  {
    key: 'panel-dockerV2',
    title: 'Docker V2 Registry',
    content: {
      key: 'panel-dockerV2-content',
      content: (
        <>
          <div>
            Regular docker v2 registries include dockerhub, quay.io,
            artifactory, docker registry v2 container, redhat public container
            registry, and many others. Generally, if you can execute a ‘docker
            login’ with a pair of credentials, Anchore can use those.
          </div>
          <List ordered className={s.helpList}>
            <List.Item>
              <List.Header>Registry</List.Header>
              <List.Description>
                Hostname or IP of your registry endpoint, with an optional port.
                <div>Ex: docker.io, mydocker.com:5000, 192.168.1.20:5000</div>
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Type</List.Header>
              <List.Description>
                Set to <strong>docker_v2</strong>
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Username</List.Header>
              <List.Description>
                Username that has access to the registry
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Password</List.Header>
              <List.Description>
                Password for the specified user
              </List.Description>
            </List.Item>
          </List>
        </>
      ),
    },
  },
  {
    key: 'panel-aws',
    title: 'Amazon Web Services Registry (AWS ECR)',
    content: {
      key: 'panel-aws-content',
      content: (
        <>
          <div>
            There are three different modes that require different settings for
            Username and Password when adding an ECR registry, depending on
            where your Anchore Enterprise is running and how your AWS IAM
            settings are configured to allow access to a given ECR registry.
          </div>
          <Accordion.Accordion
            panels={awsOptions}
            style={{ marginTop: '0.5rem', marginLeft: '1.5rem' }}
          />
        </>
      ),
    },
  },
  {
    key: 'panel-gcr',
    title: 'Google Container Registry (GCR)',
    content: {
      key: 'panel-gcr-content',
      content: (
        <>
          <div>
            When working with Google Container Registry, it is recommended that
            you use service account JSON keys rather than the short lived access
            tokens. Learn more about how to generate a JSON key&nbsp;
            <a
              href="https://cloud.google.com/container-registry/docs/advanced-authentication#json_key_file"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </div>
          <List ordered className={s.helpList}>
            <List.Item>
              <List.Header>Registry</List.Header>
              <List.Description>
                GCR registry hostname endpoint
                <div>Ex: gcr.io, us.gcr.io, eu.gcr.io, asia.gcr.io</div>
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Type</List.Header>
              <List.Description>
                Set to <strong>docker_v2</strong>
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Username</List.Header>
              <List.Description>
                Set to <strong>_json_key</strong>
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Password</List.Header>
              <List.Description>
                Full JSON string of your JSON key (the content of the key.json
                file you got from GCR)
              </List.Description>
            </List.Item>
          </List>
        </>
      ),
    },
  },
  {
    key: 'panel-azure',
    title: 'Microsoft Azure',
    content: {
      key: 'panel-azure-content',
      content: (
        <>
          <div>
            To use an Azure Registry, you can configure Anchore to use either
            the admin credential(s) or a service principal. Refer to Azure
            documentation for differences and how to setup each.
          </div>
          <Accordion.Accordion
            panels={azureOptions}
            style={{ marginTop: '0.5rem', marginLeft: '1.5rem' }}
          />
        </>
      ),
    },
  },
];

const helpText = [
  {
    key: 'registry-help-text',
    title: {
      key: 'registry-help-text-title',
      content: (
        <span>
          Need some help setting up your registry?&nbsp;
          <Icon color="orange" name="question circle outline" />
        </span>
      ),
    },
    content: {
      key: 'registry-help-text-content',
      content: (
        <div>
          <Accordion.Accordion
            panels={registryOptions}
            style={{ marginTop: '-0.5rem', marginLeft: '1.5rem' }}
          />
        </div>
      ),
    },
  },
];

type RegistryViewStates = {
  error: React.ReactNode;
  add: React.ReactNode;
  edit?: React.ReactNode;
  loading: React.ReactNode;
  list: React.ReactNode;
};

export type RegistryViewType = keyof RegistryViewStates;

const checkRegProps = (
  reg: Partial<CreateRegistryActionParams>,
): CreateRegistryActionParams | undefined => {
  const { name, registry, type, username, password, selfSigned, validate } =
    reg;
  if (
    ((typeof name === 'string' && name.length) || !name) &&
    Boolean(registry) &&
    Boolean(type) &&
    Boolean(username) &&
    Boolean(password) &&
    typeof selfSigned === 'boolean' &&
    typeof validate === 'boolean'
  )
    return reg as CreateRegistryActionParams;
  return undefined;
};

export interface RegistryListProps {
  id?: string | boolean;
  defaultView?: RegistryViewType;
  trigger?: React.ReactElement;
  onAddSuccess?: () => void;
  permissions: Record<string, boolean>;
}

const RegistryList = ({
  permissions,
  trigger,
  id = true,
  defaultView = 'list',
  onAddSuccess,
}: RegistryListProps) => {
  const {
    addRegistryError,
    errorMsg,
    fetchRegistriesError,
    isAddingRegistry,
    isUpdatingRegistry,
    isFetchingRegistries,
    isRemoveRegistryModalOpen,
    isRemovingRegistry,
    registries,
    removeRegistryError,
    updateRegistryError,
    viewProps,
  } = useAppSelector(state => state.system);
  const dispatch = useAppDispatch();

  const { topnav } = useAppSelector(state => state.app.topnav.viewProps);

  // View mode state storage hook
  const [viewMode, setViewMode] = useState<RegistryViewType>(() => 'loading');

  // Temporary registry item storage hook
  const [newReg, setNewReg] = useState<Partial<CreateRegistryActionParams>>({});

  /**
   * Create a normalized payload for submission to the web service. The view
   * mode determines if this is a POST or PUT operation, with the promise
   * associated with either submit operation being returned.
   * @returns {Promise<boolean>}
   */
  const saveRegistry = async () => {
    const params = checkRegProps(newReg);
    if (params) {
      const newRegPayload: CreateRegistryActionParams = {
        ...params,
        ...{
          registry: params.registry.replace(/http[s]?:\/\//gi, '').trim(),
          name: params.name.trim(),
          password: params.password.trim(),
        },
      };

      if (viewMode === 'add') {
        dispatch(
          addNewRegistry(newRegPayload, {
            validate: newRegPayload.validate,
          }),
        ).then((addResp: any) => {
          if (addResp.type.endsWith('SUCCESS')) {
            setViewMode('list');
            if (onAddSuccess) onAddSuccess();
          }
        });
      } else {
        dispatch(
          updateRegistry(newRegPayload, {
            validate: newRegPayload.validate,
          }),
        ).then((resp: any) => {
          if (resp.type.endsWith('SUCCESS')) {
            setViewMode('list');
          }
        });
      }
    }
  };

  /**
   * Factory function to create the registry list table displayed in the main
   * view of the dialog.
   * @returns {JSX.Element}
   */
  const makeRegistryListTable = () => {
    const {
      pageSize = 10,
      filterStr = '',
      page = 0,
    } = viewProps.registries || {};

    const regData = registries instanceof Array ? registries : [];

    return (
      <ReactTable
        resizable
        data={regData}
        defaultSorted={[
          {
            id: 'last_updated',
            desc: true,
          },
        ]}
        filterStr={filterStr}
        filterMethod={(filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ['registry', 'registryName', 'registryType', 'registryUser'],
            threshold: matchSorter.rankings.CONTAINS,
          })
        }
        columns={[
          {
            Header: 'Registry',
            id: 'registry',
            accessor: 'registry',
            width: 200,
          },
          {
            Header: 'Name',
            id: 'registryName',
            width: 200,
            // If a registry has no registry_name, default to registry value
            accessor: rowData => {
              const { registry, registry_name: regName } = rowData;
              return regName || registry;
            },
          },
          {
            Header: 'Type',
            id: 'registryType',
            accessor: 'registry_type',
            width: 100,
            Cell: row => <code>{row.value}</code>,
          },
          {
            Header: 'Username',
            id: 'registryUser',
            accessor: 'registry_user',
            width: 100,
          },
          {
            Header: 'Allow Self-Signed',
            headerStyle: { textAlign: 'center' },
            style: { textAlign: 'center' },
            width: 120,
            id: 'registryVerify',
            accessor: 'registry_verify',
            Cell: row => {
              const { value } = row;
              return <Checkbox checked={!value} readOnly disabled />;
            },
          },
          {
            Header: 'Last Updated',
            id: 'lastUpdated',
            accessor: 'last_updated',
            width: 120,
            Cell: row => {
              const { value } = row;
              const lastUpdated = moment.utc(value).tz(USER_TIMEZONE);

              return (
                <Popup
                  trigger={
                    <span style={{ cursor: 'pointer' }}>
                      {lastUpdated.fromNow().charAt(0).toUpperCase() +
                        lastUpdated.fromNow().slice(1)}
                    </span>
                  }
                  content={lastUpdated.format(
                    'ddd, DD MMM YYYY [at] HH:mm:ss z',
                  )}
                />
              );
            },
          },
          {
            Header: 'Actions',
            headerStyle: { textAlign: 'center' },
            id: 'actions',
            fixedWidth: 12,
            sortable: false,
            Cell: row => {
              const { original } = row;
              return (
                <div
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: '0.28571429rem',
                  }}
                >
                  <Button.Group
                    size="tiny"
                    className={s.actionBtnGrp}
                    basic
                    compact
                    style={{ backgroundColor: '#fff' }}
                  >
                    <Button
                      id="update-registry-modal-open"
                      disabled={!permissions.updateRegistry}
                      onClick={() => {
                        setNewReg({
                          registry: original.registry || '',
                          name: original.registry_name || '',
                          type: original.registry_type || '',
                          username: original.registry_user || '',
                          masked: true,
                          password: '',
                          selfSigned: !original.registry_verify,
                          validate: true,
                        });
                        setViewMode('edit');
                      }}
                    >
                      <Icon name="edit" />
                      <span>Edit</span>
                    </Button>
                    <Button
                      animated="vertical"
                      disabled={!permissions.deleteRegistry}
                      onClick={() =>
                        dispatch(setRegistryRemoveDialog(original.registry))
                      }
                    >
                      <Button.Content visible>
                        <span>Remove</span>
                      </Button.Content>
                      <Button.Content hidden>
                        <Icon name="trash" color="red" />
                      </Button.Content>
                    </Button>
                  </Button.Group>
                </div>
              );
            },
          },
        ]}
        page={page}
        pageSize={pageSize}
        showPagination={!!(regData && regData.length)}
        minRows={0}
        className="-striped animate__animated animate__fadeIn"
        getNoDataProps={() => ({
          filterStr,
          container: 'account',
          items: 'defined registry credentials',
          func: () => (
            <Button
              as="a"
              id="add-registry-modal-open-inline"
              size="tiny"
              color="blue"
              key="no-data-create-btn"
              compact
              disabled={!permissions.createRegistry}
              onClick={() => {
                setNewReg({
                  registry: '',
                  name: '',
                  type: 'docker_v2',
                  username: '',
                  masked: true,
                  password: '',
                  selfSigned: false,
                  validate: true,
                });
                setViewMode('add');
              }}
            >
              Let&#39;s add one!
            </Button>
          ),
          conf: { inline: true },
        })}
        NoDataComponent={tableProps => <NoData {...tableProps} />}
        getPaginationProps={() => ({
          items: 'Credentials',
          filterStr,
          noFocus: true,
        })}
        onPageChange={pageIndex =>
          dispatch(
            setSystemViewProps('registries', {
              page: pageIndex,
            }),
          )
        }
        onPageSizeChange={size => {
          // Normalize the page to display
          const currentRow = pageSize * page;
          const newPage = Math.floor(currentRow / size);
          dispatch(
            setSystemViewProps('registries', {
              page: newPage,
              pageSize: size,
            }),
          );
        }}
        onFilteredChange={_debounce(
          (column, value) =>
            dispatch(
              setSystemViewProps('registries', {
                filterStr: value,
                page: 0,
              }),
            ),
          300,
        )}
      >
        {(state, func, tableObj) => (
          <>
            {func()}
            <TableInfo
              state={state}
              item="Credential"
              items="Credentials"
              tableObj={tableObj}
            />
          </>
        )}
      </ReactTable>
    );
  };

  /**
   * Factory function to create the remove registry item modal dialog.
   * @returns {JSX.Element}
   */
  const removeRegistryItemModal = () => (
    <Modal
      open={!!isRemoveRegistryModalOpen}
      closeIcon
      basic
      size="tiny"
      onClose={() => dispatch(setRegistryRemoveDialog(false))}
    >
      <Header as="h2">
        <Icon color="red" name="trash" />
        <Header.Content>Delete a Registry Credential</Header.Content>
      </Header>
      <Modal.Content scrolling>
        {removeRegistryError ? (
          <ErrMessage
            deleteErr
            error={errorMsg}
            onDismiss={() => dispatch(clearRegistryRemoveError)}
          />
        ) : null}
        <Header inverted as="h3">
          You are about to remove the registry credential for&nbsp;
          <span
            style={{ wordBreak: 'break-all' }}
          >{`"${isRemoveRegistryModalOpen}"`}</span>
        </Header>
        <p>
          Once this credential is removed, you won&#39;t be able to recover
          it—are you sure you want to continue?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          autoFocus
          basic
          color="red"
          inverted
          onClick={() =>
            hideAllExceptTopModal({
              onComplete: () => {
                dispatch(setRegistryRemoveDialog(false));
              },
            })
          }
          disabled={isRemovingRegistry}
        >
          <Icon name="remove" /> No
        </Button>
        <Button
          color="green"
          inverted
          onClick={() =>
            hideAllExceptTopModal({
              onComplete: () => {
                dispatch(
                  removeRegistry({ registry: isRemoveRegistryModalOpen }),
                );
              },
            })
          }
          disabled={isRemovingRegistry}
          loading={isRemovingRegistry}
        >
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );

  // Lookup-based view state object which contains all the views this dialog
  // can present, and which themselves correspond to whatever is currently held
  // in the `viewMode` hook
  const viewStates: RegistryViewStates = {
    error: <Err inline noBorder error={errorMsg} />,

    add: (
      <>
        {viewMode === 'edit' ? (
          <Modal.Description
            id="update-registry-modal-description"
            style={{
              padding: '1.5rem',
              boxShadow: 'inset rgb(217, 218, 219) 0px -1px 0px 0px',
              position: 'relative',
              backgroundColor: 'rgb(248, 255, 255)',
            }}
          >
            <div
              style={{
                lineHeight: '2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ width: '100%' }}>
                <Grid verticalAlign="middle" columns={2}>
                  <Grid.Column width={10}>
                    <Grid.Row className={s.editLabels}>
                      <strong style={{ marginRight: '0.5rem' }}>
                        Registry:
                      </strong>
                      <span className={s.label}>{newReg.registry}</span>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Grid.Row className={s.editLabels}>
                      <strong style={{ marginRight: '0.5rem' }}>Type:</strong>
                      <span className={s.label}>{newReg.type}</span>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </span>
            </div>
          </Modal.Description>
        ) : undefined}

        <Modal.Content className={s.root}>
          <Form
            id="add-edit-registry-form"
            onSubmit={saveRegistry}
            onKeyDown={({ key }: { key: string }) =>
              key === 'Enter' && saveRegistry()
            }
            error={addRegistryError || updateRegistryError}
          >
            {addRegistryError || updateRegistryError ? (
              <ErrMessage
                error={errorMsg}
                onDismiss={() => dispatch(clearRegistryAddError)}
              />
            ) : null}
            {viewMode === 'add' ? (
              <p>
                To add a new registry credential, enter the address of a unique
                registry, populate the required fields, and then click&nbsp;
                <strong>OK</strong>.
              </p>
            ) : (
              <p>
                Edit the details of the selected registry credential by using
                the inputs below, and then click&nbsp;
                <strong>OK</strong>.
              </p>
            )}
            {viewMode === 'add' ? (
              <Form.Group>
                <Form.Field width="16">
                  <Form.Input
                    id={`${
                      viewMode === 'add' ? 'add' : 'update'
                    }-registry-modal-registry`}
                    label="Registry Hostname or IP Address"
                    name="registry"
                    required
                    autoComplete="off"
                    autoFocus
                    value={newReg.registry}
                    spellCheck={false}
                    placeholder="Hostname or IP of your registry endpoint, with an optional port"
                    onChange={(e, { value }) => {
                      setNewReg({
                        ...newReg,
                        ...{
                          registry: value,
                        },
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            ) : undefined}
            <Form.Group>
              <Form.Field width={viewMode === 'add' ? 10 : 16}>
                <Form.Input
                  id={`${
                    viewMode === 'add' ? 'add' : 'update'
                  }-registry-modal-name`}
                  maxLength={255}
                  label={
                    <label
                      htmlFor={`${
                        viewMode === 'add' ? 'add' : 'update'
                      }-registry-modal-name`}
                    >
                      Name
                      <Popup
                        position="right center"
                        trigger={
                          <span>
                            &nbsp;
                            <Icon
                              name="question circle outline"
                              color="orange"
                              fitted
                            />
                          </span>
                        }
                        content={
                          <div>
                            Provide an optional name to associate with this
                            record. By default, if no name is provided, the
                            registry hostname (or address) is used instead.
                          </div>
                        }
                      />
                    </label>
                  }
                  name="name"
                  autoComplete="off"
                  autoFocus={viewMode === 'edit'}
                  value={newReg.name}
                  spellCheck={false}
                  placeholder="Human readable name associated with registry record"
                  onChange={(e, { value }) => {
                    setNewReg({
                      ...newReg,
                      ...{
                        name: value,
                      },
                    });
                  }}
                />
              </Form.Field>
              {viewMode === 'add' ? (
                <Form.Field width="6">
                  <Form.Select
                    id={`${
                      viewMode === 'add' ? 'add' : 'update'
                    }-registry-modal-type`}
                    label="Type"
                    name="type"
                    required
                    autoComplete="off"
                    spellCheck={false}
                    value={newReg.type}
                    options={regTypes}
                    placeholder="Select a registry type"
                    onChange={(e, { value }) => {
                      setNewReg({
                        ...newReg,
                        ...{
                          type: value as string,
                        },
                      });
                    }}
                  />
                </Form.Field>
              ) : undefined}
            </Form.Group>
            <Form.Group>
              <Form.Field width="8">
                <Form.Input
                  id={`${
                    viewMode === 'add' ? 'add' : 'update'
                  }-registry-modal-username`}
                  label="Username"
                  name="username"
                  required
                  autoComplete="off"
                  spellCheck={false}
                  value={newReg.username}
                  placeholder="Username"
                  onChange={(e, { value }) => {
                    setNewReg({
                      ...newReg,
                      ...{
                        username: value.trim(),
                      },
                    });
                  }}
                />
              </Form.Field>
              <Form.Field width="8">
                <Form.Input
                  id={`${
                    viewMode === 'add' ? 'add' : 'update'
                  }-registry-modal-password`}
                  label="Password"
                  name="password"
                  required
                  autoComplete="off"
                  spellCheck={false}
                  placeholder="Password"
                  defaultValue={newReg.password}
                  type={newReg.masked ? 'password' : 'text'}
                  onChange={(e, { value }) => {
                    setNewReg({
                      ...newReg,
                      ...{
                        password: value,
                      },
                    });
                  }}
                  icon={{
                    name: newReg.masked ? 'eye' : 'eye slash',
                    link: true,
                    onClick: () => {
                      setNewReg({
                        ...newReg,
                        ...{
                          masked: !newReg.masked,
                        },
                      });
                    },
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group style={{ marginTop: '1.75rem' }}>
              <Form.Field
                width="8"
                style={{ display: 'flex', alignItems: 'baseline' }}
              >
                <Form.Checkbox
                  id={`${
                    viewMode === 'add' ? 'add' : 'update'
                  }-registry-modal-selfSigned`}
                  label="Allow Self-Signed"
                  name="selfSigned"
                  checked={newReg.selfSigned}
                  onChange={(e, { checked }) => {
                    setNewReg({
                      ...newReg,
                      ...{
                        selfSigned: checked,
                      },
                    });
                  }}
                  style={{ marginRight: '0.25rem' }}
                  toggle
                  className={classNames(
                    s.checkbox,
                    newReg.selfSigned ? s.checked : null,
                  )}
                />
                <Popup
                  position="top center"
                  trigger={
                    <Icon color="orange" name="question circle outline" />
                  }
                  content="Checking this option disables TLS/SSL verification for the registry URL"
                />
              </Form.Field>
              <Form.Field
                width="8"
                style={{ display: 'flex', alignItems: 'baseline' }}
              >
                <Form.Checkbox
                  id={`${
                    viewMode === 'add' ? 'add' : 'update'
                  }-registry-modal-validate`}
                  label={`Validate on ${viewMode === 'add' ? 'Add' : 'Update'}`}
                  name="validate"
                  checked={newReg.validate}
                  onChange={(e, { checked }) => {
                    setNewReg({
                      ...newReg,
                      ...{
                        validate: checked,
                      },
                    });
                  }}
                  style={{ marginRight: '0.25rem' }}
                  toggle
                  className={classNames(
                    s.checkbox,
                    newReg.validate ? s.checked : null,
                  )}
                />
                <Popup
                  position="top center"
                  trigger={
                    <Icon color="orange" name="question circle outline" />
                  }
                  content="When checked, requires registry/credentials to pass validation before adding"
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <Accordion panels={helpText} />
        </Modal.Content>
        <Modal.Actions>
          <CancelButton
            disabled={isAddingRegistry || isUpdatingRegistry}
            onClick={() => setViewMode('list')}
          />
          <Button
            color="green"
            disabled={
              !checkRegProps(newReg) || isAddingRegistry || isUpdatingRegistry
            }
            loading={isAddingRegistry || isUpdatingRegistry}
            onClick={saveRegistry}
          >
            <Icon name="checkmark" /> OK
          </Button>
        </Modal.Actions>
      </>
    ),

    loading: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '10rem',
        }}
      >
        <Dimmer active inverted>
          <Loader inverted size="huge" active inline="centered">
            Loading Registry Credentials...
          </Loader>
        </Dimmer>
      </div>
    ),

    list: (
      <>
        <Modal.Content className={s.root}>
          <Grid
            verticalAlign="middle"
            centered
            columns={2}
            className="animate__animated animate__fadeIn"
            style={{
              marginBottom: 0,
            }}
          >
            <Grid.Row>
              <Grid.Column width="10">
                <p
                  style={{ fontWeight: 500 }}
                  data-test="page-identifier-systemregistries"
                >
                  Your registry credentials are shown below. Click&nbsp;
                  <Icon name="edit" />
                  <strong>Edit</strong> to modify their associated details.
                </p>
              </Grid.Column>
              <Grid.Column width="6" textAlign="right">
                <Button
                  icon
                  id={`${
                    viewMode === 'add' ? 'add' : 'update'
                  }-registry-modal-open`}
                  basic
                  color="blue"
                  floated="right"
                  labelPosition="right"
                  size="small"
                  disabled={!permissions.createRegistry}
                  onClick={() => {
                    setNewReg({
                      registry: '',
                      name: '',
                      type: 'docker_v2',
                      username: '',
                      masked: true,
                      password: '',
                      selfSigned: false,
                      validate: true,
                    });

                    setViewMode('add');
                  }}
                >
                  <Icon name="plus" />
                  Add New Registry Credential
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {makeRegistryListTable()}
          {removeRegistryItemModal()}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              dispatch(
                setTopnavViewProps('topnav', {
                  registryitm: false,
                  registryDialogOnClose: false,
                }),
              );
            }}
            disabled={isRemovingRegistry}
          >
            <Icon name="remove" /> Close
          </Button>
        </Modal.Actions>
      </>
    ),
  };

  // Proxy to allow us to use `edit` as a view mode flag (although it's the same
  // as `add` in terms of the element it contains)
  viewStates.edit = viewStates.add;

  // Effect that switches the modal view mode into the `error` state in the
  // event of a registry item fetch error
  useEffect(() => {
    if (fetchRegistriesError) {
      setViewMode('error');
    }
  }, [fetchRegistriesError]);

  // Effect that presents the loading spinner when registry items are being
  // fetched
  useEffect(() => {
    if (isFetchingRegistries) {
      setViewMode('loading');
    }
  }, [isFetchingRegistries]);

  // Effect that places the modal into the registry item list mode after
  // registries are loaded (or updated)
  useEffect(() => {
    if (
      !fetchRegistriesError &&
      !isFetchingRegistries &&
      registries &&
      !['add', 'edit'].includes(viewMode)
    ) {
      if (defaultView === 'add') {
        setNewReg({
          registry: '',
          name: '',
          type: 'docker_v2',
          username: '',
          masked: true,
          password: '',
          selfSigned: false,
          validate: true,
        });
      }
      setViewMode(defaultView);
    }
  }, [registries]);

  // Effect that hides (or shows) any other open modal dialog boxes when the
  // remove registry item dialog is opened (or closed)
  useEffect(() => {
    if (isRemoveRegistryModalOpen) {
      hideAllExceptTopModal();
    } else {
      hideAllExceptTopModal({
        onComplete: () => dispatch(setRegistryRemoveDialog(false)),
      });
    }
  }, [isRemoveRegistryModalOpen]);

  // When the dialog switches into the `add` or `edit` view modes, this effect
  // clears any existing errors
  useEffect(() => {
    if (viewMode === 'add') {
      dispatch(clearRegistryAddError());
    } else if (viewMode === 'edit') {
      dispatch(clearRegistryUpdateError());
    }
  }, [viewMode]);

  // Effect that hides (or shows) any other open modal dialog boxes when the
  // main registry item dialog is opened (or closed)
  useEffect(() => {
    if (topnav.registryitm) {
      hideAllExceptTopModal({
        onComplete: () => dispatch(fetchRegistries()),
      });
    } else {
      // Prevent the modal from being in list mode on reopen
      hideAllExceptTopModal({
        onComplete: () => setViewMode('loading'),
      });
    }
  }, [topnav.registryitm]);

  return (
    <Modal
      open={topnav.registryitm === id}
      onClose={() => {
        dispatch(
          setTopnavViewProps('topnav', {
            registryitm: false,
          }),
        );
      }}
      size={viewMode === 'list' ? 'large' : 'small'}
      closeIcon
      trigger={trigger}
    >
      <Header className={s.header}>
        {viewMode === 'list' ? (
          <span>Add / Edit Registry Credentials</span>
        ) : (
          <span>
            {viewMode === 'add' ? 'Add a New' : 'Edit'} Registry Credential
          </span>
        )}
        {permissions &&
        permissions.listRegistries &&
        !permissions.createRegistry ? (
          <Popup
            position="top center"
            trigger={
              <Label basic className={s.readOnlyLabel}>
                <Icon
                  name="lock"
                  color="red"
                  style={{ marginRight: '0.5rem' }}
                />
                <span>Read-Only</span>
              </Label>
            }
            content="Registry creation and editing operations have been disabled by the administrator."
          />
        ) : null}
      </Header>
      {viewStates[`${viewMode}`]}
    </Modal>
  );
};

export default withStyles(s)(RegistryList);
